import React, { createContext, useReducer } from "react";

const orderReducer = (state, action) => {
  switch (action.type) {
    case "ADD_PRODUCT": {
      let newOrder;
      const productIsInTheList = state.order.find(
        (product) => product.code === action.payload.code
      );

      if (productIsInTheList) {
        newOrder = state.order.reduce((list, product) => {
          if (product.code === action.payload.code) {
            return [
              ...list,
              { ...product, qty: product.qty + action.payload.qty },
            ];
          }

          return [...list, product];
        }, []);
      } else {
        newOrder = [...state.order, action.payload];
      }

      localStorage.setItem("order", JSON.stringify(newOrder));
      return { ...state, order: newOrder, lastAdded: action.payload };
    }
    case "REMOVE_PRODUCT": {
      const newOrder = state.order.filter(
        (product) => product.code !== action.payload.code
      );
      localStorage.setItem("order", JSON.stringify(newOrder));
      return { ...state, order: newOrder };
    }
    case "LOAD_ORDER_FROM_STORAGE":
      return { ...state, order: action.payload };
    case "RESET_LAST_ADDED":
      return { ...state, lastAdded: undefined };
    case "SEND_ORDER_SUCCESS":
      localStorage.clear();
      return { order: [], lastAdded: undefined };
    default:
      return state;
  }
};

export const OrderStateContext = createContext();
export const OrderDispatchContext = createContext();

export function OrderContextProvider({ children }) {
  const [state, dispatch] = useReducer(orderReducer, {
    order: [],
    lastAdded: undefined,
  });

  return (
    <OrderDispatchContext.Provider value={dispatch}>
      <OrderStateContext.Provider value={state}>
        {children}
      </OrderStateContext.Provider>
    </OrderDispatchContext.Provider>
  );
}

export default ({ element }) => (
  <OrderContextProvider>{element}</OrderContextProvider>
);
